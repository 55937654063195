 <template>
    <modal
        height="auto"
        :show="true"
        scrollable
        size="middle"
        @hide="$emit('hide')"
    >
        <div slot="header">
            <h2 class="font-normal">Edit service</h2>
        </div>
        <div slot="body">
            <form @submit.prevent="">
                <modal-field label="First transport service setting" class="mb-3">
                    <input v-model="first_transport_service_setting" type="text" />
                </modal-field>

                <modal-field label="Second transport service setting" class="mb-3">
                    <input v-model="second_transport_service_setting" type="text" />
                </modal-field>

                <modal-field label="Third transport service setting" class="mb-3">
                    <input v-model="third_transport_service_setting" type="text" />
                </modal-field>

                <modal-field label="Fourth transport service setting" class="mb-3">
                    <input v-model="fourth_transport_service_setting" type="text" />
                </modal-field>

                <modal-field label="Transport mode" class="mb-3">
                    <input v-model="transport_mode" type="text" />
                </modal-field>

                <modal-field label="Variations">
                    <multi-select
                        v-model="variations"
                        :branding="false"
                        :dynamic="true"
                        :multiple="true"
                        :options="[]">
                    </multi-select>
                </modal-field>
            </form>
        </div>
        <div slot="footer" class="w__100-p">
            <div class="flex justify-end w-full w__100-p">
                <div class="flex flex__justify-end">
                    <button class="px-10 btn-primary" :disabled="loading" @click="send">Save</button>
                    <button class="px-10 btn-outline ml-3" @click="$emit('hide')">Cancel</button>
                </div>
            </div>
        </div>
    </modal>
</template>
<script>
export default {
    props: {
        value: {
            type: Object,
            default: () => {},
        },
        index: {
            type: Number,
        }
    },

    name: 'CarrierServiceModal',

    data() {
        return {
            first_transport_service_setting: this.value.first_transport_service_setting,
            second_transport_service_setting: this.value.second_transport_service_setting,
            third_transport_service_setting: this.value.third_transport_service_setting,
            fourth_transport_service_setting: this.value.fourth_transport_service_setting,
            transport_mode: this.value.transport_mode,
            variations: this.value.variations,
            loading: false,
        }
    },

    methods: {
        send() {
            this.loading = true;

            try {
                const value = this.value;

                value.first_transport_service_setting = this.first_transport_service_setting;
                value.second_transport_service_setting = this.second_transport_service_setting;
                value.third_transport_service_setting = this.third_transport_service_setting;
                value.fourth_transport_service_setting = this.fourth_transport_service_setting;
                value.transport_mode = this.transport_mode;
                value.variations = this.variations;

                this.$emit('update', {
                    value,
                    index: this.index
                });

                this.$emit('hide');

                this.$snotify.success(`Successfully`);
            } catch(error) {
                this.$snotify.error(error);
            } finally {
                this.loading = false;
            }
        },
    },
}
</script>
